import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "prerequisites"
    }}>{`Prerequisites`}</h3>
    <p>{`To use PaymentsDS with the Ruby SDK you need to have:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.ruby-lang.org"
        }}>{`Ruby 2.5+`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://rubygems.org"
        }}>{`RubyGems`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://bundler.io"
        }}>{`Bundler`}</a></li>
    </ul>
    <h3 {...{
      "id": "receive-money-from-a-mobile-account-to-a-business-account"
    }}>{`Receive money from a mobile account to a business account`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`require 'paymentsds/mpesa'

client = Paymentsds::MPesa::Client.new do |config|
   config.api_key = '<REPLACE>'               # API Key
   config.public_key = '<REPLACE>'            # Public Key
   config.service_provider_code = '<REPLACE>' # input_ServiceProviderCode
end

begin
   payment_data = {
      from: '841234567',       # input_CustomerMSISDN
      reference: '11114',      # input_ThirdPartyReference
      transaction: 'T12344CC', # input_TransactionReference
      amount: '10'             # input_Amount
   }

   result = client.receive(payment_data)

   if result.success?
      puts result.data
   end
rescue
   puts 'Operation failed'
end
`}</code></pre>
    <h3 {...{
      "id": "send-money-from-a-business-account-to-a-mobile-account"
    }}>{`Send money from a business account to a mobile account`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`require 'paymentsds/mpesa'

client = Paymentsds::MPesa::Client.new do |config|
   config.api_key = '<REPLACE>'               # API Key
   config.public_key = '<REPLACE>'            # Public Key
   config.service_provider_code = '<REPLACE>' # input_ServiceProviderCode
end

begin
   payment_data = {
      to: '841234567',       # input_CustomerMSISDN
      reference: '11114',      # input_ThirdPartyReference
      transaction: 'T12344CC', # input_TransactionReference
      amount: '10'             # input_Amount
   }

   result = client.send(payment_data)

   if result.success?
      puts result.data
   end
rescue
   puts 'Operation failed'
end
`}</code></pre>
    <h3 {...{
      "id": "send-money-from-a-business-account-to-a-another-business-account"
    }}>{`Send money from a business account to a another business account`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`require 'paymentsds/mpesa'

client = Paymentsds::MPesa::Client.new do |config|
   config.api_key = '<REPLACE>'               # API Key
   config.public_key = '<REPLACE>'            # Public Key
   config.service_provider_code = '<REPLACE>' # input_ServiceProviderCode
end

begin
   payment_data = {
      to: '979797',            # input_ReceiverPartyCode
      reference: '11114',      # input_ThirdPartyReference
      transaction: 'T12344CC', # input_TransactionReference
      amount: '10'             # input_Amount
   }

   result = client.send(payment_data)

   if result.success?
      puts result.data
   end
rescue
   puts 'Operation failed'
end
`}</code></pre>
    <h3 {...{
      "id": "revert-a-transaction"
    }}>{`Revert a transaction`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`require 'paymentsds/mpesa'

client = Paymentsds::MPesa::Client.new do |config|
   config.api_key = '<REPLACE>'               # API Key
   config.public_key = '<REPLACE>'            # Public Key
   config.service_provider_code = '<REPLACE>' # input_ServiceProviderCode
   config.initiator_identifier = '<REPLACE>'  # input_InitiatorIdentifier,
   config.security_identifier = '<REPLACE>'   # input_SecurityCredential
end

begin
   reversion_data = {
      reference: '11114',      # input_ThirdPartyReference
      transaction: 'T12344CC', # input_TransactionReference
      amount: '10'             # input_ReversalAmounts
   }

   result = client.reversion(payment_data)

   if result.success?
      # Handle success scenario
   end
rescue
   # Handle failure scenario
end
`}</code></pre>
    <h3 {...{
      "id": "query-the-status-of-a-transaction"
    }}>{`Query the status of a transaction`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      